import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import styled from "styled-components"
import Layout from "../layouts"
import Seo from "../components/seo"
import {
  BreadCrumb,
  Container,
  Section,
  SectionTitle,
  SectionDescription,
  MarkdownBody,
  MarkdownContent,
  SectionInnerWhite,
} from "../components/Section"
import {
  HeroContainer,
  Hero,
  Background,
  HeroCaption,
  HeroTitle,
  HeroDescription,
} from "../components/Hero"
import {
  ListInfoContainer,
  ListInfo,
  ListInfoItem,
  CardInfo,
  CardIcon,
  CardDesc,
} from "../components/Benefits"
import ExpertSection from "../components/Expert"
import ProductCard from "../components/ProductCard"
import LineArrowRight from "../components/Icons/LineArrowRight"
import PrimaryButton from "../components/Button/PrimaryButton"
import FinancingForm from "../components/FinancingForm"
import BreakpointUp from "../components/Media/BreakpointUp"
import BreakpointDown from "../components/Media/BreakpointDown"
import RequestModal from "../components/RequestModal"

const HeroActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px -5px 0 -5px;
  ${BreakpointUp.sm`
        margin: 40px -5px 0 -5px;
    `}
`
const HeroActionItem = styled.div`
  position: relative;
  width: 100%;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  padding: 5px;
`
const SectionInnerBody = styled.div`
  border-bottom: 1px solid #ccd6e0;
  padding-bottom: 20px;
  margin-bottom: 20px;
  ${BreakpointUp.sm` 
        padding-bottom:40px;
        margin-bottom:40px;
    `}
  ${BreakpointUp.xl` 
        padding-bottom:60px;
        margin-bottom:60px;
    `}
    ${BreakpointUp.xxl` 
        padding-bottom:80px;
        margin-bottom:80px;
    `}
    &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`
const SectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`
const SectionGridCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.lg` 
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
`
const StepGrid = styled.div`
  margin: auto;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
  ${BreakpointUp.sm`	    
		grid-template-columns: repeat(2, 1fr);
    `}
  ${BreakpointUp.md`
        align-items: flex-start;
	    grid-template-columns: repeat(4, 1fr);
    `}
`
const StepItem = styled.div`
  margin-top: ${props => props.mt};
  border-bottom: 1px solid #ccd6e0;
  border-left: none;
  padding: 10px 10px;
  margin: 10px 0;
  &:last-child {
    ${BreakpointDown.sm`
      border-bottom:none;
    `}
  }
  ${BreakpointUp.sm`
    border-left: 1px solid #CCD6E0;
    border-bottom:none;
    padding: 5px 15px;
  `}
  ${BreakpointUp.lg`
    padding: 5px 30px;
  `}
	& strong {
    display: block;
    color: rgba(0, 0, 0, 0.2);
    font-family: "Barlow", sans-serif;
    text-align: left;
    font-size: 42px;
    line-height: 54px;
    font-weight: 700;
    margin-bottom: 10px;
    ${BreakpointUp.lg`
      font-size: 64px;
      line-height: 80px;
    `}
  }
  & p {
    color: #000;
    font-family: "Barlow", sans-serif;
    text-align: left;
    font-weight: 500;
    margin-bottom: 0;
    ${BreakpointUp.md`
      font-size: 18px;
      line-height: 26px;
    `}
    ${BreakpointUp.lg`
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    `}
  }
  &:nth-child(2),
  &:nth-child(4) {
    ${BreakpointUp.sm`	
      margin-top:100px;
    `}
  }
`

const RentToOwnPage = ({ location, data }) => {
  const [isQuoteVisible, setIsQuoteVisible] = useState(false)
  const Scrollify = () =>
    document
      .querySelector("#financing")
      .scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  const HandleModalOpen = () => {
    setIsQuoteVisible(true)
    document.querySelector("body").classList.add("modal-open")
  }
  const HandleModalClose = () => {
    setIsQuoteVisible(false)
    document.querySelector("body").classList.remove("modal-open")
  }

  return (
    <Layout location={location}>
      <Seo
        title={data.contentfulRentToOwnPage.metaTitle}
        description={
          data.contentfulRentToOwnPage.metaDescription.metaDescription
        }
      />
      <Section
        xpt="114px"
        mpt="94px"
        pt="64px"
        xpb="0"
        mpb="0"
        pb="0"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <HeroContainer>
            <Hero bgAfterBefore="none">
              <Background>
                <StaticImage
                  src="../images/banner-rto.jpg"
                  placeholder="blurred"
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="banner-static-bg"
                />
              </Background>
              <HeroCaption>
                <BreadCrumb className="white" position="static" translate="0">
                  <Link to="/">Home</Link>
                  <span>About Rent to Own</span>
                </BreadCrumb>
                <HeroTitle>{data.contentfulRentToOwnPage.title}</HeroTitle>
                <HeroDescription maxWidth="1496px">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data.contentfulRentToOwnPage.description
                          .childMarkdownRemark.html,
                    }}
                  />
                </HeroDescription>
                <HeroActions>
                  <HeroActionItem>
                    <button type="button" onClick={Scrollify}>
                      <PrimaryButton
                        text="Apply Now"
                        icon={<LineArrowRight />}
                        size="lg"
                      />
                    </button>
                  </HeroActionItem>
                </HeroActions>
              </HeroCaption>
            </Hero>
          </HeroContainer>
        </Container>
      </Section>
      <Section
        xpt="0"
        mpt="0"
        pt="0"
        xpb="30px"
        mpb="20px"
        pb="15px"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionInnerWhite>
            <SectionInnerBody>
              <SectionTitle mb="20px">
                {data.contentfulRentToOwnPage.benefitsSectionTitle}
              </SectionTitle>
              <ListInfoContainer>
                <ListInfo>
                  {data.contentfulRentToOwnPage.benefits.map((item, i) => (
                    <ListInfoItem key={i}>
                      <CardInfo>
                        <CardIcon>
                          <img src={item.image.file.url} alt={item.title} />
                        </CardIcon>
                        <CardDesc>
                          <strong>{item.title}</strong>
                          <p>{item.description.description}</p>
                        </CardDesc>
                      </CardInfo>
                    </ListInfoItem>
                  ))}
                </ListInfo>
              </ListInfoContainer>
            </SectionInnerBody>
            <SectionInnerBody>
              <SectionTitle mb="20px">
                {data.contentfulRentToOwnPage.howSectionTitle}
              </SectionTitle>
              <SectionDescription maxWidth="1240px">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      data.contentfulRentToOwnPage.howSectionDescription
                        .childMarkdownRemark.html,
                  }}
                />
              </SectionDescription>
              <StepGrid>
                {data.contentfulRentToOwnPage.howToApply.map(item => (
                  <StepItem key={item.no}>
                    <strong>{item.no}</strong>
                    <p>{item.text} </p>
                  </StepItem>
                ))}
              </StepGrid>
            </SectionInnerBody>
            {/* <SectionInnerBody>
                      <SectionTitle mb="20px">{data.contentfulRentToOwnPage.whySectionTitle}</SectionTitle>
                      <SectionDescription maxWidth="1140px" mb="0">
                          <div dangerouslySetInnerHTML={{__html: data.contentfulRentToOwnPage.whySectionDescription.childMarkdownRemark.html}} />
                          <GatsbyImage image={getImage(data.contentfulRentToOwnPage.whySectionImage)} alt="rent-to-own" />
                      </SectionDescription>    
                    </SectionInnerBody> */}
          </SectionInnerWhite>
        </Container>
      </Section>
      <Section
        xpt="30px"
        mpt="20px"
        pt="15px"
        xpb="90px"
        mpb="70px"
        pb="40px"
        bgColor="transparent"
        id="financing"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionInnerWhite>
            <SectionTitle>
              {data.contentfulRentToOwnPage.formSectionTitle}
            </SectionTitle>
            <SectionDescription maxWidth="1140px">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    data.contentfulRentToOwnPage.formSectionDescription
                      .childMarkdownRemark.html,
                }}
              />
            </SectionDescription>
            <FinancingForm location={location} formName="RTO Form" />
          </SectionInnerWhite>
        </Container>
      </Section>

      <ExpertSection />

      <Section
        xpt="60px"
        mpt="40px"
        pt="30px"
        xpb="30px"
        mpb="20px"
        pb="15px"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <MarkdownBody>
            <MarkdownContent maxWidth="1496px">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    data.contentfulRentToOwnPage.content.childMarkdownRemark
                      .html,
                }}
              />
            </MarkdownContent>
          </MarkdownBody>
        </Container>
      </Section>

      <Section
        xpt="30px"
        mpt="20px"
        pt="15px"
        xpb="30px"
        mpb="10px"
        pb="0"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionTitle>
            {data.contentfulRentToOwnPage.productSectionTitle}
          </SectionTitle>
          <SectionDescription maxWidth="1496px">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data.contentfulRentToOwnPage.productSectionDescription
                    .childMarkdownRemark.html,
              }}
            />
          </SectionDescription>
          <SectionGrid>
            {data.allContentfulProduct.edges.map((item, i) => {
              if (i < 3) {
                return (
                  <SectionGridCol key={i}>
                    <ProductCard
                      data={item.node}
                      openQuoteModal={HandleModalOpen}
                    />
                  </SectionGridCol>
                )
              }
              return <></>
            })}
          </SectionGrid>
        </Container>
      </Section>
      <RequestModal
        location={location}
        isVisible={isQuoteVisible}
        onClose={HandleModalClose}
      />
    </Layout>
  )
}

export default RentToOwnPage

export const query = graphql`
  query RentToOwnPageQuery {
    contentfulRentToOwnPage {
      title
      metaTitle
      metaDescription {
        metaDescription
      }
      description {
        description
        childMarkdownRemark {
          html
        }
      }
      benefitsSectionTitle
      benefits {
        title
        image {
          file {
            url
          }
          gatsbyImageData(quality: 100)
        }
        description {
          description
          childMarkdownRemark {
            html
          }
        }
      }
      howSectionTitle
      howSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      howToApply {
        no
        text
      }
      whySectionTitle
      whySectionDescription {
        childMarkdownRemark {
          html
        }
      }
      whySectionImage {
        gatsbyImageData(quality: 100)
      }
      formSectionTitle
      formSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      productSectionTitle
      productSectionDescription {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          id
          name
          url
          description {
            childMarkdownRemark {
              html
            }
          }
          category {
            name
          }
          subCategory {
            name
          }
          sku
          roofType {
            name
          }
          width
          length
          height
          price
          image {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
          warranty {
            warranty
          }
          otherSpec {
            otherSpec
          }
        }
      }
    }
  }
`
